body{
    margin: 0px;
    height: 100%;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.login_page{
    display: grid;
    grid-template-columns: 1fr 1fr; /* Deux colonnes égales */
    grid-template-rows: 1fr; /* Assure que les colonnes prennent toute la hauteur */
    background-image: url("/public/images/white.jpg");
    background-position: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    @media screen and (max-width: 1010px){
        display: flex;
        flex-direction: column;
        height: max-content;
    }
    &__content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 40px 60px;
        @media screen and (max-width: 1010px){
            padding: 30px 20px;
        }
        &__head{
            display: flex;
            flex-direction: column;
            &__logo{

            }
            &__text{
                margin: 40px 0px;
                font-size: 50px;
                font-weight: bold;
                @media screen and (max-width: 1010px){
                    font-size: 30px;
                }
            }
        }
        &__form{
            display: flex;
            flex-direction: column;
            input{
                padding: 20px;
                font-size: 20px;
                margin: 8px 0px;
                border-radius: 10px;
                border: none;
                outline: solid 1px gray;
            }
            input:focus{
                outline: solid 1.5px #1dc4ff;
                box-shadow: 0px 0px 5px 0px black;
            }
            &__btns{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-size: 20px;
                margin: 8px 0px;
                a{
                    color: lighten($color: #f67a00, $amount: 5);
                    text-decoration: none;
                }
                &__submit{
                    padding: 10px 30px;
                    background-color: #1dc4ff;
                    border-radius: 10px;
                    color: white;
                    font-size: 25px;
                    &:hover{
                        cursor: pointer;
                        color: black;
                    }
                }
            }
        }
        &__foot{
            margin-top: 0px;
            @media screen and (max-width: 1010px){
                text-align: center;
            }
        }
    }
    &__response{
        margin-top: 10px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
            font-size: 30px;
        }
    }
    &__picture{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
            width: 100%;
            border-radius: 20%;
        }
    }
}

.admin_dashboard{
    display: grid;
    grid-template-columns: 1fr 11fr; /* Deux colonnes égales */
    grid-template-rows: 1fr; /* Assure que les colonnes prennent toute la hauteur */
    height: 100vh;
    @media screen and (max-width: 1010px){
        display: flex;
        flex-direction: row;
        height: 100vh;
    }
    &__main_bar{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        @media screen and (max-width: 1010px){
            padding-left: 10px;
            padding-right: 10px;
        }
        @media screen and (max-width: 500px){
            padding-left: 5px;
            padding-right: 5px;
        }
        &__logo{
            width: 50px;
            height: 50px;
            border-radius: 100%;
            margin-top: 30px;
            img{
                width: 100%;
                height: 100%;
                border-radius: 100%;
            }
            @media screen and (max-width: 500px){
                width: 40px;
                height: 40px;
            }
        }
        &__items{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            :first-child{
                margin-top: 0px;
            }
            &__btn{
                margin-top: 40px;
                width: 40px;
                height: 40px;
                background-color: #eff1f8;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                font-size: 20px;
                &:hover{
                    cursor: pointer;
                    scale: 1.2;
                    background-color: #f67a00;
                }
                @media screen and (max-width: 500px){
                    font-size: 15px;
                }
            }
        }
        &__profil{
            font-size: 20px;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background-color: #eff1f8;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            &:hover{
                cursor: pointer;
                box-shadow: 0px 0px 3px 0px black;
            }
            @media screen and (max-width: 500px){
                width: 40px;
                height: 40px;
                font-size: 15px;
            }
        }
    }
    &__container{
        display: grid;
        grid-template-columns: 2fr 1fr; /* Deux colonnes égales */
        grid-template-rows: 1fr; /* Assure que les colonnes prennent toute la hauteur */        
        flex-direction: row;
        @media screen and (max-width: 1010px){
            display: flex;
            flex-direction: column;
            padding: 0px 10px;
            overflow-y: auto;
        }
        @media screen and (max-width: 500px){
            padding: 0px 5px;
        }
        &__stats{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 30px 0px;
            @media screen and (max-width: 1010px){
                display: flex;
                flex-direction: column;
            }
            &__diagram{
                display: flex;
                flex-direction: column;
                &__head{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    background-color: white;
                    border: solid 1px gray;
                    border-radius: 10px;
                    padding: 15px 5px;
                    margin-bottom: 10px;
                    &__title{
                        font-size: 20px;
                        font-weight: 500;
                        text-align: center;
                        @media screen and (max-width: 1010px){
                            font-size: 15px;
                            font-weight: 400;
                        }
                    }
                    &__date{
                        font-size: 20px;
                        @media screen and (max-width: 1010px){
                            font-size: 15px;
                        }
                        input{
                            font-size: 20px;
                            background-color: white;
                            border: none;
                            outline: none;
                            @media screen and (max-width: 1010px){
                                font-size: 15px;
                            }
                        }
                    }
                }
                &__container{
                    background-color: black;
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media screen and (max-width: 1010px){
                        height: auto;
                    }
                }
            }
            &__okrs{
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                flex-wrap: wrap;
                &__stat{
                    display: flex;
                    flex-direction: column;
                    background-color: #1dc4ff;
                    font-size: 20px;
                    padding: 19px 20px;
                    border-radius: 20px;
                    width: 20%;
                    height: max-content;
                    margin: 10px 0px;
                    @media screen and (max-width: 1010px){
                        width: 25%;
                    }
                    @media screen and (max-width: 500px){
                        width: 36%;
                        font-size: 15px;
                    }
                    &__head{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        border-bottom: solid 1px black;
                        padding-bottom: 5px;
                        &__item{
                            font-weight: 100;
                        }
                        &__icon{
                            font-weight: bold;
                            color: #eff1f8;
                        }
                    }
                    &__data{
                        margin-top: 10px;
                        font-weight: bold;
                    }
                }
            }
        }
        &__reps_list{
            display: flex;
            flex-direction: column;
            padding: 30px;
            @media screen and (max-width: 1010px){
                padding: 0px;
            }
            &__head{
                display: flex;
                flex-direction: column;
                &__title{
                    font-size: 25px;
                    font-weight: bold;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-direction: row;
                    justify-content: space-between;
                    color: #1dc4ff;
                    border: solid 1px gray;
                    border-radius: 10px;
                    padding: 13px 5px;
                    i{
                        color: black;
                    }
                    @media screen and (max-width: 500px){
                        font-size: 15px;
                    }
                }
                &__search_bar{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin: 10px 0px;
                    background-color: #eff1f8;
                    border-radius: 10px;
                    input{
                        border: none;
                        outline: none;
                        background-color: #eff1f8;
                        padding: 15px;
                        font-size: 20px;
                        border-radius: 20px;
                        @media screen and (max-width: 500px){
                            font-size: 15px;
                        }
                    }
                    i{
                        padding: 15px;
                        color: #1dc4ff;
                    }
                }
            }
            &__container{
                display: flex;
                flex-direction: column;
                max-height: 77vh;
                overflow-y: auto;
                border-top: solid 1px black;
                &__empty{
                    margin-top: 20px;
                    color: gray;
                    text-align: center;
                }
                &__performance{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin: 7px 0px;
                    padding: 3px;
                    &:hover{
                        border-radius: 10px;
                        cursor: pointer;
                        background-color: lighten($color: #f67a00, $amount: 40);
                    }
                    &__profil{
                        font-size: 20px;
                        width: 50px;
                        height: 50px;
                        border-radius: 100%;
                        background-color: darken($color: #eff1f8, $amount: 8);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @media screen and (max-width: 500px){
                            font-size: 15px;
                        }
                    }
                    &__stats{
                        display: flex;
                        flex-direction: column;
                        width: 83%;
                        &__stats1{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            font-weight: 500;
                            padding-bottom: 5px;
                        }
                        &__stats2{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            font-weight: 100;
                        }
                    }
                }
            }
        }
    }
}

.performance_add_panel{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 40%;
    background-color: #eff1f8;
    border-radius: 20px;
    box-shadow: 0px 0px 5px 0px black;
    z-index: 20;
    @media screen and (max-width: 1010px){
        width: 80%;
    }
    @media screen and (max-width: 500px){
        width: 95%;
    }
    &__head{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0px;
        &__logo{
            font-size: 50px;
            color: #1dc4ff;
        }
        &__title{
            font-weight: 400;
            font-size: 20px;
            margin-top: 10px;
        }
    }
    &__btns{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        
        margin: 20px;
        &__cancel, &__save{
            padding: 15px;
            text-align: center;
            box-shadow: 0px 0px 3px 0px gray;
            &:hover{
                cursor: pointer;
                scale: 1.03;
            }

        }
        &__save{
            background-color: #f67a00;
        }
    }
    &__form{
        margin: 20px;
        margin-top: 40px;
        :first-child{
            margin-top: 0px;
        }
        &__reps{
            margin-top: 10px;
            &__input{
                margin-top: 5px;
                select{
                    width: 100%;
                    font-size: 20px;
                    padding: 10px 5px;
                    border-radius: 10px;
                    outline: none;
                    background-color: white;
                    border: none;
                    box-shadow: 0px 0px 3px 0px gray;
                }
                option{
                    margin: 5px 0px;
                    font-size: 15px;
                }
            }
        }
        &__items{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            white-space: 10px;
            width: 100%;
            margin-top: 15px;
            :first-child{
                margin-left: 0px;
                margin-right: 5px;
            }
        }
        &__item{
            margin-left: 5px;
            margin-right: 0px;
            &__input{
                margin-top: 5px;
                input{
                    width: 100%;
                    font-size: 20px;
                    padding: 10px 0px;
                    border-radius: 10px;
                    outline: none;
                    text-align: center;
                    border: none;
                    box-shadow: 0px 0px 3px 0px gray;
                }
            }
        }
        &__cb{
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 10px;
        }
    }
    &__result{
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
        color: #f67a00;
    }
}

.arriere_plan{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.5;
}

.view_performance{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 60%;
    height: 80%;
    background-color: #eff1f8;
    border-radius: 20px;
    box-shadow: 0px 0px 5px 0px black;
    z-index: 20;
    display: grid;
    grid-template-columns: 4fr 3fr;
    grid-template-rows: 1fr;
    @media screen and (max-width: 1010px){
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 77.5%;
        overflow-y: auto;
        &__close{
            color: #eff1f8;
        }
    }
    @media screen and (max-width: 500px){
        width: 95%;
    }
    &__close{
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 20px;
    }
    &__export{
        position: absolute;
        top: 10px;
        left: 40px;
        font-size: 20px;
        color: #eff1f8;
        &:hover{
            cursor: pointer;
            color: #f67a00;
        }
    }
    &__stats{
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 3fr 2.2fr;
        background-color: lighten($color: #1dc4ff, $amount: 20);
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        @media screen and (max-width: 1010px) {
            border-bottom-left-radius: 0px;
        }
        &__diagram{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            background-color: black;
            margin: 2px;
        }
        &__okrs{
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            border-bottom-left-radius: 20px;
            &__stat{
                display: flex;
                flex-direction: column;
                background-color: #1dc4ff;
                font-size: 15px;
                padding: 10px;
                border-radius: 20px;
                width: 25%;
                height: max-content;
                margin: 5px;
                @media screen and (max-width: 1010px){
                    width: 26%;
                }
                @media screen and (max-width: 500px){
                    width: 40%;
                }
                &__head{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    border-bottom: solid 1px black;
                    padding-bottom: 5px;
                    &__item{
                        font-weight: 100;
                    }
                    &__icon{
                        font-weight: bold;
                        color: #eff1f8;
                    }
                }
                &__data{
                    margin-top: 10px;
                    font-weight: bold;
                }
            }
        }
    }
    &__edit{
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        position: relative;
        padding: 0px 5px;
        overflow-y: auto;
        @media screen and (max-width: 1010px){
            padding: 20px 0px;
            min-height: 50%;
        }
        &__reps_head{
            padding: 15px;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
        }
        &__head{
            padding: 10px;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            margin: 5px;
            border: solid 1px black;
            border-radius: 10px;
            margin-bottom: 50px;
        }
        &__form{
            margin: 10px 5px;
        }
        &__btns{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 10px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            font-size: 20px;
            :first-child{
                background-color: darken($color: #eff1f8, $amount: 5);
            }
            @media screen and (max-width: 1010px){
                position: relative;
                margin-top: 40px;
            }
            &__btn{
                background-color: #1dc4ff;
                margin-left: 10px;
                padding: 10px;
                text-align: center;
                box-shadow: 0px 0px 3px 0px gray;
                &:hover{
                    cursor: pointer;
                    scale: 1.03;
                }
            }
        }
    }
    &__refresh{
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 15px;
        color: #eff1f8;
        &:hover{
            cursor: pointer;
            color: #f67a00;
        }
    }
}

.stat{
    &__green{
        background-color: rgb(17, 199, 17);
    }
    &__red{
        background-color: rgb(249, 70, 70);
    }
    &__yellow{
        background-color: rgb(220, 220, 57);
    }
}

.useraddpanel{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 60%;
    height: 80%;
    background-color: #eff1f8;
    border-radius: 20px;
    box-shadow: 0px 0px 5px 0px black;
    z-index: 20;
    display: grid;
    grid-template-columns: 4fr 3fr;
    grid-template-rows: 1fr;
    @media screen and (max-width: 1010px){
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 77.5%;
        overflow-y: auto;
    }
    @media screen and (max-width: 500px){
        width: 95%;
        height: 93%;
    }
    &__form{
        background-color: darken($color: #eff1f8, $amount: 10);
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        position: relative;
        @media screen and (max-width: 1010px) {
            padding-bottom: 20px;
            border-bottom-left-radius: 0px;
        }
        &__head{
            font-size: 25px;
            font-weight: bold;
            margin: 20px;
            text-align: center;
            @media screen and (max-width: 500px){
                font-size: 20px;
            }
        }
        &__container{
            display: flex;
            flex-direction: column;
            margin: 0px 10px;
            input{
                padding: 10px;
                font-size: 20px;
                border: none;
                outline: none;
                margin-bottom: 15px;
            }
        }
        &__btns{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 10px;
            position: absolute;
            bottom: 0;
            right: 0;
            :first-child{
                margin-left: 0px;
                background-color: #eff1f8;
            }
            &__item{
                margin-left: 20px;
                padding: 10px;
                background-color: #f67a00;
                font-size: 20px;
                &:hover{
                    cursor: pointer;
                    scale: 1.02;
                }
            }
        }
    }
    &__members{
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        overflow-y: auto;
        &__head{
            font-size: 20px;
            font-weight: bold;
            padding: 15px;
            text-align: center;
            background-color: darken($color: #eff1f8, $amount: 10);
            margin: 10px;
            @media screen and (max-width: 500px){
                margin: 10px 5px;
                font-size: 15px;
            }
        }
        &__container{
            display: flex;
            flex-direction: column;
            margin: 10px;
            &__item{
                padding: 10px 5px;
                margin-bottom: 5px;
                background-color: darken($color: #eff1f8, $amount: 2);
                @media screen and (max-width: 500px){
                    padding: 10px;
                }
            }
            @media screen and (max-width: 500px){
                margin: 10px 0px;
            }
        }
    }
}

.error-message{
    margin: 10px;
    text-align: center;
    color: red;
}

.parametres{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 40%;
    background-color: #eff1f8;
    border-radius: 20px;
    box-shadow: 0px 0px 5px 0px black;
    z-index: 20;
    @media screen and (max-width: 1010px){
        width: 75%;
    }
    &__head{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 20px;
        padding: 15px 10px;
        border-bottom: solid 1px black;
    }
    &__infos{
        display: flex;
        flex-direction: column;
        padding: 10px;
        font-size: 17px;
        &__item{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 5px 0px;
            &__title{
            }
            &__content{
                margin-left: 10px;
                color: #1dc4ff;
                font-weight: bold;
            }
        }
    }
    &__password{
        margin: 10px;
        margin-top: 10px;
        &__head{
            font-weight: bold;
        }
        &__message{
            color: #f67a00;
            text-align: center;
            margin-top: 10px;
        }
        &__form{
            display: flex;
            flex-direction: column;
            margin: 10px 0px;
            :first-child{
                margin-top: 0px;
            }
            input{
                padding: 10px;
                border: none;
                outline: none;
                margin-top: 10px;
                border: solid 0.5px black;
            }
            &__btns{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-top: 10px;
                &__item{
                    padding: 10px;
                    background-color: #f67a00;
                    font-size: 15px;
                    &:hover{
                        scale: 1.02;
                        cursor: pointer;
                    }
                }
                .loading{
                    margin-right: 10px;
                }
            }
        }
    }
}


.export_view{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 30%;
    background-color: #eff1f8;
    border-radius: 20px;
    box-shadow: 0px 0px 5px 0px black;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    text-align: center;
    @media screen and (max-width: 1010px){
        width: 75%;
    }
    &__text{
        font-size: 20px;
        font-weight: bold;
    }
    &__btns{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        justify-content: center;
        &__close, &__export{
            padding: 10px;
            background-color: darken($color: #eff1f8, $amount: 10);
            color: black;
            font-size: 20px;
            margin: 0px 10px;
            &:hover{
                cursor: pointer;
                box-shadow: 0px 0px 2px 0px black;
            }
        }
        &__export{
            background-color: #1dc4ff;
        }
    }
}


.import-view{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 35%;
    background-color: #eff1f8;
    border-radius: 20px;
    box-shadow: 0px 0px 5px 0px black;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    text-align: center;
    @media screen and (max-width: 1010px){
        width: 75%;
    }
    &__text{
        font-size: 20px;
        font-weight: 500;
        margin: 5px 0px;
        margin-bottom: 15px;
    }
    &__date{
        margin: 5px 0px;
        padding: 0px 10px;
        background-color: darken($color: #eff1f8, $amount: 5);
        input{
            font-size: 20px;
            padding: 20px 0px;
            width: 100%;
            border: none;
            outline: none;
            background-color: darken($color: #eff1f8, $amount: 5);
        }
    }
    &__file{
        margin: 5px 0px;
        padding: 20px 10px;
        background-color: darken($color: #eff1f8, $amount: 5);
        input{
            font-size: 20px;
        }
    }
    &__btns{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 20px;
        &__item{
            padding: 10px;
            background-color: #1dc4ff;
            margin-left: 10px;
            font-size: 20px;
            &:hover{
                cursor: pointer;
                box-shadow: 0px 0px 2px 0px black;
            }
        }
        :last-child{
            background-color: #f67a00;
        }
    }
}
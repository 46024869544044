@charset "UTF-8";
body {
  margin: 0px;
  height: 100%;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.login_page {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Deux colonnes égales */
  grid-template-rows: 1fr; /* Assure que les colonnes prennent toute la hauteur */
  background-image: url("/public/images/white.jpg");
  background-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
@media screen and (max-width: 1010px) {
  .login_page {
    display: flex;
    flex-direction: column;
    height: max-content;
  }
}
.login_page__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 60px;
}
@media screen and (max-width: 1010px) {
  .login_page__content {
    padding: 30px 20px;
  }
}
.login_page__content__head {
  display: flex;
  flex-direction: column;
}
.login_page__content__head__text {
  margin: 40px 0px;
  font-size: 50px;
  font-weight: bold;
}
@media screen and (max-width: 1010px) {
  .login_page__content__head__text {
    font-size: 30px;
  }
}
.login_page__content__form {
  display: flex;
  flex-direction: column;
}
.login_page__content__form input {
  padding: 20px;
  font-size: 20px;
  margin: 8px 0px;
  border-radius: 10px;
  border: none;
  outline: solid 1px gray;
}
.login_page__content__form input:focus {
  outline: solid 1.5px #1dc4ff;
  box-shadow: 0px 0px 5px 0px black;
}
.login_page__content__form__btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin: 8px 0px;
}
.login_page__content__form__btns a {
  color: #ff8711;
  text-decoration: none;
}
.login_page__content__form__btns__submit {
  padding: 10px 30px;
  background-color: #1dc4ff;
  border-radius: 10px;
  color: white;
  font-size: 25px;
}
.login_page__content__form__btns__submit:hover {
  cursor: pointer;
  color: black;
}
.login_page__content__foot {
  margin-top: 0px;
}
@media screen and (max-width: 1010px) {
  .login_page__content__foot {
    text-align: center;
  }
}
.login_page__response {
  margin-top: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_page__response i {
  font-size: 30px;
}
.login_page__picture {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login_page__picture img {
  width: 100%;
  border-radius: 20%;
}

.admin_dashboard {
  display: grid;
  grid-template-columns: 1fr 11fr; /* Deux colonnes égales */
  grid-template-rows: 1fr; /* Assure que les colonnes prennent toute la hauteur */
  height: 100vh;
}
@media screen and (max-width: 1010px) {
  .admin_dashboard {
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
}
.admin_dashboard__main_bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media screen and (max-width: 1010px) {
  .admin_dashboard__main_bar {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 500px) {
  .admin_dashboard__main_bar {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.admin_dashboard__main_bar__logo {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-top: 30px;
}
.admin_dashboard__main_bar__logo img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
@media screen and (max-width: 500px) {
  .admin_dashboard__main_bar__logo {
    width: 40px;
    height: 40px;
  }
}
.admin_dashboard__main_bar__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.admin_dashboard__main_bar__items :first-child {
  margin-top: 0px;
}
.admin_dashboard__main_bar__items__btn {
  margin-top: 40px;
  width: 40px;
  height: 40px;
  background-color: #eff1f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 20px;
}
.admin_dashboard__main_bar__items__btn:hover {
  cursor: pointer;
  scale: 1.2;
  background-color: #f67a00;
}
@media screen and (max-width: 500px) {
  .admin_dashboard__main_bar__items__btn {
    font-size: 15px;
  }
}
.admin_dashboard__main_bar__profil {
  font-size: 20px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #eff1f8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.admin_dashboard__main_bar__profil:hover {
  cursor: pointer;
  box-shadow: 0px 0px 3px 0px black;
}
@media screen and (max-width: 500px) {
  .admin_dashboard__main_bar__profil {
    width: 40px;
    height: 40px;
    font-size: 15px;
  }
}
.admin_dashboard__container {
  display: grid;
  grid-template-columns: 2fr 1fr; /* Deux colonnes égales */
  grid-template-rows: 1fr; /* Assure que les colonnes prennent toute la hauteur */
  flex-direction: row;
}
@media screen and (max-width: 1010px) {
  .admin_dashboard__container {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    overflow-y: auto;
  }
}
@media screen and (max-width: 500px) {
  .admin_dashboard__container {
    padding: 0px 5px;
  }
}
.admin_dashboard__container__stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0px;
}
@media screen and (max-width: 1010px) {
  .admin_dashboard__container__stats {
    display: flex;
    flex-direction: column;
  }
}
.admin_dashboard__container__stats__diagram {
  display: flex;
  flex-direction: column;
}
.admin_dashboard__container__stats__diagram__head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: solid 1px gray;
  border-radius: 10px;
  padding: 15px 5px;
  margin-bottom: 10px;
}
.admin_dashboard__container__stats__diagram__head__title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width: 1010px) {
  .admin_dashboard__container__stats__diagram__head__title {
    font-size: 15px;
    font-weight: 400;
  }
}
.admin_dashboard__container__stats__diagram__head__date {
  font-size: 20px;
}
@media screen and (max-width: 1010px) {
  .admin_dashboard__container__stats__diagram__head__date {
    font-size: 15px;
  }
}
.admin_dashboard__container__stats__diagram__head__date input {
  font-size: 20px;
  background-color: white;
  border: none;
  outline: none;
}
@media screen and (max-width: 1010px) {
  .admin_dashboard__container__stats__diagram__head__date input {
    font-size: 15px;
  }
}
.admin_dashboard__container__stats__diagram__container {
  background-color: black;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1010px) {
  .admin_dashboard__container__stats__diagram__container {
    height: auto;
  }
}
.admin_dashboard__container__stats__okrs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.admin_dashboard__container__stats__okrs__stat {
  display: flex;
  flex-direction: column;
  background-color: #1dc4ff;
  font-size: 20px;
  padding: 19px 20px;
  border-radius: 20px;
  width: 20%;
  height: max-content;
  margin: 10px 0px;
}
@media screen and (max-width: 1010px) {
  .admin_dashboard__container__stats__okrs__stat {
    width: 25%;
  }
}
@media screen and (max-width: 500px) {
  .admin_dashboard__container__stats__okrs__stat {
    width: 36%;
    font-size: 15px;
  }
}
.admin_dashboard__container__stats__okrs__stat__head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px black;
  padding-bottom: 5px;
}
.admin_dashboard__container__stats__okrs__stat__head__item {
  font-weight: 100;
}
.admin_dashboard__container__stats__okrs__stat__head__icon {
  font-weight: bold;
  color: #eff1f8;
}
.admin_dashboard__container__stats__okrs__stat__data {
  margin-top: 10px;
  font-weight: bold;
}
.admin_dashboard__container__reps_list {
  display: flex;
  flex-direction: column;
  padding: 30px;
}
@media screen and (max-width: 1010px) {
  .admin_dashboard__container__reps_list {
    padding: 0px;
  }
}
.admin_dashboard__container__reps_list__head {
  display: flex;
  flex-direction: column;
}
.admin_dashboard__container__reps_list__head__title {
  font-size: 25px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: #1dc4ff;
  border: solid 1px gray;
  border-radius: 10px;
  padding: 13px 5px;
}
.admin_dashboard__container__reps_list__head__title i {
  color: black;
}
@media screen and (max-width: 500px) {
  .admin_dashboard__container__reps_list__head__title {
    font-size: 15px;
  }
}
.admin_dashboard__container__reps_list__head__search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  background-color: #eff1f8;
  border-radius: 10px;
}
.admin_dashboard__container__reps_list__head__search_bar input {
  border: none;
  outline: none;
  background-color: #eff1f8;
  padding: 15px;
  font-size: 20px;
  border-radius: 20px;
}
@media screen and (max-width: 500px) {
  .admin_dashboard__container__reps_list__head__search_bar input {
    font-size: 15px;
  }
}
.admin_dashboard__container__reps_list__head__search_bar i {
  padding: 15px;
  color: #1dc4ff;
}
.admin_dashboard__container__reps_list__container {
  display: flex;
  flex-direction: column;
  max-height: 77vh;
  overflow-y: auto;
  border-top: solid 1px black;
}
.admin_dashboard__container__reps_list__container__empty {
  margin-top: 20px;
  color: gray;
  text-align: center;
}
.admin_dashboard__container__reps_list__container__performance {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 7px 0px;
  padding: 3px;
}
.admin_dashboard__container__reps_list__container__performance:hover {
  border-radius: 10px;
  cursor: pointer;
  background-color: #ffe1c3;
}
.admin_dashboard__container__reps_list__container__performance__profil {
  font-size: 20px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #d3d8ec;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 500px) {
  .admin_dashboard__container__reps_list__container__performance__profil {
    font-size: 15px;
  }
}
.admin_dashboard__container__reps_list__container__performance__stats {
  display: flex;
  flex-direction: column;
  width: 83%;
}
.admin_dashboard__container__reps_list__container__performance__stats__stats1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
  padding-bottom: 5px;
}
.admin_dashboard__container__reps_list__container__performance__stats__stats2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 100;
}

.performance_add_panel {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 40%;
  background-color: #eff1f8;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px black;
  z-index: 20;
}
@media screen and (max-width: 1010px) {
  .performance_add_panel {
    width: 80%;
  }
}
@media screen and (max-width: 500px) {
  .performance_add_panel {
    width: 95%;
  }
}
.performance_add_panel__head {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
}
.performance_add_panel__head__logo {
  font-size: 50px;
  color: #1dc4ff;
}
.performance_add_panel__head__title {
  font-weight: 400;
  font-size: 20px;
  margin-top: 10px;
}
.performance_add_panel__btns {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 20px;
}
.performance_add_panel__btns__cancel, .performance_add_panel__btns__save {
  padding: 15px;
  text-align: center;
  box-shadow: 0px 0px 3px 0px gray;
}
.performance_add_panel__btns__cancel:hover, .performance_add_panel__btns__save:hover {
  cursor: pointer;
  scale: 1.03;
}
.performance_add_panel__btns__save {
  background-color: #f67a00;
}
.performance_add_panel__form {
  margin: 20px;
  margin-top: 40px;
}
.performance_add_panel__form :first-child {
  margin-top: 0px;
}
.performance_add_panel__form__reps {
  margin-top: 10px;
}
.performance_add_panel__form__reps__input {
  margin-top: 5px;
}
.performance_add_panel__form__reps__input select {
  width: 100%;
  font-size: 20px;
  padding: 10px 5px;
  border-radius: 10px;
  outline: none;
  background-color: white;
  border: none;
  box-shadow: 0px 0px 3px 0px gray;
}
.performance_add_panel__form__reps__input option {
  margin: 5px 0px;
  font-size: 15px;
}
.performance_add_panel__form__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  white-space: 10px;
  width: 100%;
  margin-top: 15px;
}
.performance_add_panel__form__items :first-child {
  margin-left: 0px;
  margin-right: 5px;
}
.performance_add_panel__form__item {
  margin-left: 5px;
  margin-right: 0px;
}
.performance_add_panel__form__item__input {
  margin-top: 5px;
}
.performance_add_panel__form__item__input input {
  width: 100%;
  font-size: 20px;
  padding: 10px 0px;
  border-radius: 10px;
  outline: none;
  text-align: center;
  border: none;
  box-shadow: 0px 0px 3px 0px gray;
}
.performance_add_panel__form__cb {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
}
.performance_add_panel__result {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  color: #f67a00;
}

.arriere_plan {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.5;
}

.view_performance {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 60%;
  height: 80%;
  background-color: #eff1f8;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px black;
  z-index: 20;
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-template-rows: 1fr;
}
@media screen and (max-width: 1010px) {
  .view_performance {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 77.5%;
    overflow-y: auto;
  }
  .view_performance__close {
    color: #eff1f8;
  }
}
@media screen and (max-width: 500px) {
  .view_performance {
    width: 95%;
  }
}
.view_performance__close {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
}
.view_performance__export {
  position: absolute;
  top: 10px;
  left: 40px;
  font-size: 20px;
  color: #eff1f8;
}
.view_performance__export:hover {
  cursor: pointer;
  color: #f67a00;
}
.view_performance__stats {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 2.2fr;
  background-color: #83dfff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
@media screen and (max-width: 1010px) {
  .view_performance__stats {
    border-bottom-left-radius: 0px;
  }
}
.view_performance__stats__diagram {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: black;
  margin: 2px;
}
.view_performance__stats__okrs {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom-left-radius: 20px;
}
.view_performance__stats__okrs__stat {
  display: flex;
  flex-direction: column;
  background-color: #1dc4ff;
  font-size: 15px;
  padding: 10px;
  border-radius: 20px;
  width: 25%;
  height: max-content;
  margin: 5px;
}
@media screen and (max-width: 1010px) {
  .view_performance__stats__okrs__stat {
    width: 26%;
  }
}
@media screen and (max-width: 500px) {
  .view_performance__stats__okrs__stat {
    width: 40%;
  }
}
.view_performance__stats__okrs__stat__head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px black;
  padding-bottom: 5px;
}
.view_performance__stats__okrs__stat__head__item {
  font-weight: 100;
}
.view_performance__stats__okrs__stat__head__icon {
  font-weight: bold;
  color: #eff1f8;
}
.view_performance__stats__okrs__stat__data {
  margin-top: 10px;
  font-weight: bold;
}
.view_performance__edit {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  padding: 0px 5px;
  overflow-y: auto;
}
@media screen and (max-width: 1010px) {
  .view_performance__edit {
    padding: 20px 0px;
    min-height: 50%;
  }
}
.view_performance__edit__reps_head {
  padding: 15px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.view_performance__edit__head {
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 5px;
  border: solid 1px black;
  border-radius: 10px;
  margin-bottom: 50px;
}
.view_performance__edit__form {
  margin: 10px 5px;
}
.view_performance__edit__btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 20px;
}
.view_performance__edit__btns :first-child {
  background-color: #dde1f0;
}
@media screen and (max-width: 1010px) {
  .view_performance__edit__btns {
    position: relative;
    margin-top: 40px;
  }
}
.view_performance__edit__btns__btn {
  background-color: #1dc4ff;
  margin-left: 10px;
  padding: 10px;
  text-align: center;
  box-shadow: 0px 0px 3px 0px gray;
}
.view_performance__edit__btns__btn:hover {
  cursor: pointer;
  scale: 1.03;
}
.view_performance__refresh {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 15px;
  color: #eff1f8;
}
.view_performance__refresh:hover {
  cursor: pointer;
  color: #f67a00;
}

.stat__green {
  background-color: rgb(17, 199, 17);
}
.stat__red {
  background-color: rgb(249, 70, 70);
}
.stat__yellow {
  background-color: rgb(220, 220, 57);
}

.useraddpanel {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 60%;
  height: 80%;
  background-color: #eff1f8;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px black;
  z-index: 20;
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-template-rows: 1fr;
}
@media screen and (max-width: 1010px) {
  .useraddpanel {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 77.5%;
    overflow-y: auto;
  }
}
@media screen and (max-width: 500px) {
  .useraddpanel {
    width: 95%;
    height: 93%;
  }
}
.useraddpanel__form {
  background-color: #ccd2e8;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
}
@media screen and (max-width: 1010px) {
  .useraddpanel__form {
    padding-bottom: 20px;
    border-bottom-left-radius: 0px;
  }
}
.useraddpanel__form__head {
  font-size: 25px;
  font-weight: bold;
  margin: 20px;
  text-align: center;
}
@media screen and (max-width: 500px) {
  .useraddpanel__form__head {
    font-size: 20px;
  }
}
.useraddpanel__form__container {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
}
.useraddpanel__form__container input {
  padding: 10px;
  font-size: 20px;
  border: none;
  outline: none;
  margin-bottom: 15px;
}
.useraddpanel__form__btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.useraddpanel__form__btns :first-child {
  margin-left: 0px;
  background-color: #eff1f8;
}
.useraddpanel__form__btns__item {
  margin-left: 20px;
  padding: 10px;
  background-color: #f67a00;
  font-size: 20px;
}
.useraddpanel__form__btns__item:hover {
  cursor: pointer;
  scale: 1.02;
}
.useraddpanel__members {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow-y: auto;
}
.useraddpanel__members__head {
  font-size: 20px;
  font-weight: bold;
  padding: 15px;
  text-align: center;
  background-color: #ccd2e8;
  margin: 10px;
}
@media screen and (max-width: 500px) {
  .useraddpanel__members__head {
    margin: 10px 5px;
    font-size: 15px;
  }
}
.useraddpanel__members__container {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.useraddpanel__members__container__item {
  padding: 10px 5px;
  margin-bottom: 5px;
  background-color: #e8ebf5;
}
@media screen and (max-width: 500px) {
  .useraddpanel__members__container__item {
    padding: 10px;
  }
}
@media screen and (max-width: 500px) {
  .useraddpanel__members__container {
    margin: 10px 0px;
  }
}

.error-message {
  margin: 10px;
  text-align: center;
  color: red;
}

.parametres {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 40%;
  background-color: #eff1f8;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px black;
  z-index: 20;
}
@media screen and (max-width: 1010px) {
  .parametres {
    width: 75%;
  }
}
.parametres__head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  padding: 15px 10px;
  border-bottom: solid 1px black;
}
.parametres__infos {
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-size: 17px;
}
.parametres__infos__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px;
}
.parametres__infos__item__content {
  margin-left: 10px;
  color: #1dc4ff;
  font-weight: bold;
}
.parametres__password {
  margin: 10px;
  margin-top: 10px;
}
.parametres__password__head {
  font-weight: bold;
}
.parametres__password__message {
  color: #f67a00;
  text-align: center;
  margin-top: 10px;
}
.parametres__password__form {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}
.parametres__password__form :first-child {
  margin-top: 0px;
}
.parametres__password__form input {
  padding: 10px;
  border: none;
  outline: none;
  margin-top: 10px;
  border: solid 0.5px black;
}
.parametres__password__form__btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.parametres__password__form__btns__item {
  padding: 10px;
  background-color: #f67a00;
  font-size: 15px;
}
.parametres__password__form__btns__item:hover {
  scale: 1.02;
  cursor: pointer;
}
.parametres__password__form__btns .loading {
  margin-right: 10px;
}

.export_view {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 30%;
  background-color: #eff1f8;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px black;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  text-align: center;
}
@media screen and (max-width: 1010px) {
  .export_view {
    width: 75%;
  }
}
.export_view__text {
  font-size: 20px;
  font-weight: bold;
}
.export_view__btns {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: center;
}
.export_view__btns__close, .export_view__btns__export {
  padding: 10px;
  background-color: #ccd2e8;
  color: black;
  font-size: 20px;
  margin: 0px 10px;
}
.export_view__btns__close:hover, .export_view__btns__export:hover {
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px black;
}
.export_view__btns__export {
  background-color: #1dc4ff;
}

.import-view {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 35%;
  background-color: #eff1f8;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px black;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  text-align: center;
}
@media screen and (max-width: 1010px) {
  .import-view {
    width: 75%;
  }
}
.import-view__text {
  font-size: 20px;
  font-weight: 500;
  margin: 5px 0px;
  margin-bottom: 15px;
}
.import-view__date {
  margin: 5px 0px;
  padding: 0px 10px;
  background-color: #dde1f0;
}
.import-view__date input {
  font-size: 20px;
  padding: 20px 0px;
  width: 100%;
  border: none;
  outline: none;
  background-color: #dde1f0;
}
.import-view__file {
  margin: 5px 0px;
  padding: 20px 10px;
  background-color: #dde1f0;
}
.import-view__file input {
  font-size: 20px;
}
.import-view__btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}
.import-view__btns__item {
  padding: 10px;
  background-color: #1dc4ff;
  margin-left: 10px;
  font-size: 20px;
}
.import-view__btns__item:hover {
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px black;
}
.import-view__btns :last-child {
  background-color: #f67a00;
}

